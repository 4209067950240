import React, { useState } from "react";
import { isValidEmail } from "../../utils";
import { resetPW, signIn } from "../../api/user";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [resetPWModalOpen, setResetPWModalOpen] = useState(false);
  const [resetPWEmail, setResetPWEmail] = useState("");
  const [resetPWMessage, setResetPWMessage] = useState("");
  const [resetPWError, setResetPWError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setError("Please enter a valid email");
      return;
    } else if (password.length === 0) {
      setError("Please enter a valid password");
      return;
    } else {
      setError("");
      setLoading(true);
      signIn(email, password)
        .then((response) => {
          if (response?.data.token) {
            localStorage.setItem("userToken", response?.data.token);
            window.location.href = "/";
          }
        })
        .catch((err) => {
          if (err.response?.data?.error) {
            let errorMsg = err.response.data.error;
            if (
              errorMsg === "auth/user-not-found" ||
              errorMsg === "auth/wrong-password"
            ) {
              setError(
                "Invalid credentials. Please verify your email and password, then try again."
              );
            } else if (errorMsg === "auth/too-many-requests") {
              setError(
                "Too many requests. Please wait a moment and try again."
              );
            } else if (errorMsg === "auth/email-not-verified") {
              setError("Please verify your email");
            }
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const handleResetPW = (e) => {
    resetPW(resetPWEmail)
      .then((response) => {
        setResetPWMessage("Password reset email has been sent to your email.");
        setResetPWEmail("");
        setResetPWError("");
      })
      .catch((err) => {
        if (err.response?.data?.error) {
          if (err.response?.data?.error === "auth/user-not-found") {
            setResetPWError("User not found");
            setResetPWMessage("");
            return;
          }
        }
        setResetPWError("An error occurred");
        setResetPWMessage("");
      });
  };

  return (
    <>
      <div className="login-form-box">
        <h3 className="mb-30">Login</h3>
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="input-box mb--30">
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-box mb--30">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="color-danger mb--30">
            {error.length > 0 ? error : null}
          </div>
          <button
            className={`rn-btn edu-btn w-100 mb--30 ${
              loading ? "btn-disabled" : ""
            }`}
            disabled={loading}
            type="submit"
          >
            <span>Login</span>
          </button>
          <div className="input-box">
            <span
              className="lost-password"
              onClick={() => setResetPWModalOpen(true)}
            >
              Lost your password?
            </span>
          </div>
        </form>
      </div>
      <Modal
        open={resetPWModalOpen}
        onClose={() => {
          setResetPWModalOpen(false);
          setResetPWEmail("");
          setResetPWError("");
          setResetPWMessage("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="reset-pw-box">
            <h4>Reset Password</h4>
            <div className="input-box mb--30">
              <input
                type="text"
                placeholder="Email"
                value={resetPWEmail}
                onChange={(e) => setResetPWEmail(e.target.value)}
              />
            </div>
            <div className="color-danger mb--30">
              {resetPWError.length > 0 ? resetPWError : null}
            </div>
            <div className="color-success mb--30">
              {resetPWMessage.length > 0 ? resetPWMessage : null}
            </div>
            <div className="btn-container">
              <button
                className={`edu-btn ${
                  isValidEmail(resetPWEmail) ? "" : "btn-disabled"
                }`}
                disabled={!isValidEmail(resetPWEmail)}
                onClick={handleResetPW}
              >
                Reset
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default LoginForm;

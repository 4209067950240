import axios from "axios";

let userToken = localStorage.getItem("userToken");

const api = axios.create({
  // baseURL: "https://us-central1-skillcraft-ai.cloudfunctions.net",
  baseURL: "https://skillcraft.ai",
  headers: {
    Authorization: `Bearer ${userToken}`,
  },
});

export default api;

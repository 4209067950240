import api from "./index";

const signIn = (email, password) => {
  return api.post("/api/signin", { email, password });
};

const signUp = (email, password) => {
  return api.post("/api/signup", { email, password });
};

const resetPW = (email) => {
  return api.post("/api/resetPassword", { email });
};

export { signIn, signUp, resetPW };

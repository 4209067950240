import React, { useEffect, useState } from "react";
import { isValidEmail, isValidPassword } from "../../utils";
import { signUp } from "../../api/user";

const RegisterForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [conditionsChecked, setConditionsChecked] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (email.length > 0 && password.length > 0) {
      if (!isValidEmail(email)) {
        setError("Please enter a valid email");
        setBtnDisabled(true);
      } else if (!isValidPassword(password)) {
        setError(
          "Password must be at least 8 characters long and can contain a combination of letters, numbers, and special characters"
        );
        setBtnDisabled(true);
      } else if (confirmPassword !== password) {
        setError(
          "Password and Confirm Password fields do not match. Please make sure both fields contain the same value."
        );
        setBtnDisabled(true);
      } else if (!conditionsChecked) {
        setError(
          "Please read and agree to the Terms & Conditions before proceeding."
        );
        setBtnDisabled(true);
      } else {
        setError("");
        setBtnDisabled(false);
      }
    }
  }, [email, password, confirmPassword, conditionsChecked]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setBtnDisabled(true);
    signUp(email, password)
      .then((response) => {
        setMessage("Signup has been successful! Please check your email");
      })
      .catch((err) => {
        if (err.response?.data?.error) {
          let errorMsg = err.response.data.error;
          if (errorMsg === "auth/email-already-in-use") {
            setError(
              "Email already in use. Please use a different email address."
            );
          }
        }
      })
      .finally(() => {
        setBtnDisabled(false);
      });
  };

  return (
    <div className="login-form-box">
      <h3 className="mb-30">Register</h3>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="input-box mb--30">
          <input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input-box mb--30">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="input-box mb--30">
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <div className="color-danger mb--30">
          {error.length > 0 ? error : null}
        </div>
        <div className="color-success mb--30">
          {message.length > 0 ? message : null}
        </div>
        <button
          className={`rn-btn edu-btn w-100 mb--30 ${
            btnDisabled ? "btn-disabled" : ""
          }`}
          type="submit"
        >
          <span>Register</span>
        </button>
        <div className="input-box">
          <input
            id="checkbox-2"
            type="checkbox"
            checked={conditionsChecked}
            onChange={(e) => setConditionsChecked(e.target.checked)}
          />
          <label htmlFor="checkbox-2">
            I read & agree the terms & conditions.
          </label>
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;

import { createContext, useEffect, useState, useRef } from "react";

const defaultContext = {
  isLoggedIn: false,
  setLoggedIn: () => {},
  userToken: "",
  setUserToken: () => {},
  handleSignOut: () => {},
};

const AppContext = createContext(defaultContext);
const AppContextProvider = AppContext.Provider;
const AppContextConsumer = AppContext.Consumer;

export const useAppContext = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userToken, setUserToken] = useState("");

  useEffect(() => {
    let userToken = localStorage.getItem("userToken") || "no_data";
    if (userToken !== "no_data") {
      setLoggedIn(true);
      setUserToken(userToken);
    }
  }, []);

  const handleSignOut = () => {
    localStorage.removeItem("userToken");
    setUserToken("");
    setLoggedIn(false);
  };

  return {
    isLoggedIn,
    setLoggedIn,
    userToken,
    setUserToken,
    handleSignOut,
  };
};

export { AppContext, AppContextProvider, AppContextConsumer };
